<template>
  <a-drawer :title="title" :visible="dialogVisible" :width="width" centered="true" @close="close">
    <!-- 添加商户号 组件 -->
    <addMer ref='addMer' v-if="comName === 'addMerchants'" v-bind="$attrs" v-on="$listeners" @closeDrawer="close"></addMer>
    <!-- 添加支付渠道 组件 -->
    <!-- <addPay ref='addPay' v-if="comName === 'addPayChannel'" v-bind="$attrs" v-on="$listeners" @closeDrawer="close"></addPay> -->
    <!-- 微信支付配置项 -->
    <addwxPay ref="wxpay" v-if="comName === 'addwxPay'"  v-bind="$attrs" v-on="$listeners" @closeDrawer="close"></addwxPay>
<!--
    <div class='foot-btn'>
      <a-button :style="{ marginRight: '8px' }" @click='onClose'>
        取消
      </a-button>
      <a-button type='primary' html-type="submit">
        确定
      </a-button>
    </div> -->
  </a-drawer>
</template>
<script>
import addMer from '@/views/pay/components/addMer'
import addPay from '@/views/pay/components/addPay'
import addwxPay from '@/views/pay/components/addwxPay'

export default {
  name: 'rightDrawer',
  components: { addMer,addPay,addwxPay },
  props: {
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '720px'
    },
    // 组件的名稱 addMerchants
    comName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      if(this.comName === 'addMerchants'){
        this.$refs.addMer.onClearForm()
      }
    },
  }
}
</script>